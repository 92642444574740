<template>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
      <li>
        <a class="text-500 no-underline line-height-3 cursor-pointer"
          >MEN&Uacute; PRINCIPAL</a
        >
      </li>
      <li class="px-2">
        <i class="pi pi-angle-right text-500 line-height-3"></i>
      </li>
      <li>
        <span class="text-900 line-height-3">INICIO</span>
      </li>
    </ul>
    <div class="flex align-items-center justify-content-between mb-3 md:mb-5">
      <div class="mt-1 mb-5">
        <div class="font-medium text-3xl text-900 align-items-center">
          <strong>Bienvenido: </strong>{{ user.name }}
        </div>
      </div>
    </div>
    <div class="grid">
      <!-- <div class="col-12 lg:col-4 xl:col-12">
        <span style="font-size: 1.5rem; background-color: chartreuse; padding: 0.5rem; border-radius: 0.5rem; color: black; font-weight: bold"
          >PRODUCTOS MÁS VENDIDO: <strong>{{ productos_mas_vendidos }}</strong></span
        >
      </div> -->
      <div
        class="col-12 lg:col-4 xl:col-4"
        v-if="'Producto Crear' in authService.user.permissions"
      >
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block font-medium mb-3"
                ><strong>PRODUCTOS</strong></span
              >
              <div class="text-900 font-medium text-xl">
                Total Registros: <strong>{{ productos }}</strong>
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-box text-blue-500 text-xl"></i>
            </div>
          </div>
          <router-link to="/productos" class="pi pi-eye text-blue-500 text-xl">
            <span
              class="text-blue-500 font-medium"
              v-tooltip.top="'Ver  Listado Productos'"
            >
              Ver Productos</span
            >
          </router-link>
        </div>
      </div>

      <div
        class="col-12 lg:col-4 xl:col-4"
        v-if="'Cliente Crear' in authService.user.permissions"
      >
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block font-medium mb-3"
                ><strong>CLIENTES</strong></span
              >
              <div class="text-900 font-medium text-xl">
                Total Registros: <strong>{{ clientes }}</strong>
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-orange-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-users text-orange-500 text-xl"></i>
            </div>
          </div>
          <router-link to="/clientes" class="pi pi-eye text-orange-500 text-xl">
            <span
              class="text-orange-500 font-medium"
              v-tooltip.top="'Ver  Listado Clientes'"
            >
              Ver Clientes</span
            >
          </router-link>
        </div>
      </div>

      <div
        class="col-12 lg:col-4 xl:col-4"
        v-if="'Proveedor Crear' in authService.user.permissions"
      >
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block font-medium mb-3"
                ><strong>PROVEEDORES</strong></span
              >
              <div class="text-900 font-medium text-xl">
                Total Registros: <strong>{{ proveedores }}</strong>
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-users text-cyan-500 text-xl"></i>
            </div>
          </div>
          <router-link
            to="/proveedores"
            class="pi pi-eye text-cyan-500 text-xl"
          >
            <span
              class="text-cyan-500 font-medium"
              v-tooltip.top="'Ver  Listado Proveedores'"
            >
              Ver Proveedores</span
            >
          </router-link>
        </div>
      </div>
      <div
        class="col-12 lg:col-4 xl:col-4"
        v-if="'Dashboard Comisiones' in authService.user.permissions"
        v-show="false"
      >
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block font-medium mb-3"
                ><strong>SALDO A FAVOR</strong></span
              >
              <div class="text-900 font-medium text-xl">
                <h4>
                  POR COBRAR:
                  <strong
                    >{{
                      convertirNumeroGermanicFormat(total_comision)
                    }}
                    Bs.</strong
                  >
                </h4>
              </div>
            </div>
            <!-- <div
              class="flex align-items-center justify-content-center bg-red-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <Button
                v-tooltip.top="'Ver Comisiones Pendientes de Pago General'"
                class="p-button-rounded p-button-text p-button-danger"
                icon="pi pi-file-pdf"
                @click="descargarComisionesPDF()"
              />
            </div> -->
            <div
              class="flex align-items-center justify-content-center bg-succes-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <Button
                v-tooltip.top="'Actualizar Comisiones'"
                class="p-button-rounded p-button-text p-button-success"
                icon="pi pi-refresh"
                @click="panel_necesario_auth()"
                :loading="obtenerDatos"
              />
            </div>
            <div
              class="flex align-items-center justify-content-center bg-red-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <Button
                :disabled="this.total_comision == 0"
                v-tooltip.top="'Ver Detalle de Comisiones Pendientes de Pago'"
                class="p-button-rounded p-button-text p-button-danger"
                icon="pi pi-file-pdf"
                @click="descargarComisionesPDF()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid mt-2">
      <div
        class="col-12 md:col-12 card"
        v-if="'Dashboard Productos+Vendidos' in authService.user.permissions"
      >
        <div>
          <div class="p-panel-title text-center mb-2">
            <strong>PRODUCTOS Y SERVICIOS MÁS VENDIDOS</strong>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <Calendar
                v-model="fecha_inicio"
                :showIcon="true"
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="field col-12 md:col-4">
              <Calendar
                v-model="fecha_fin"
                :showIcon="true"
                dateFormat="dd/mm/yy"
              />
            </div>
            <div class="field col-12 md:col-4">
              <Button
                label="Buscar..."
                v-tooltip.top="'Actualizar Productos Mas Vendidos'"
                class="p-button-info"
                icon="pi pi-search"
                @click="panel_necesario_auth()"
                :loading="obtenerDatos"
              />
            </div>

            <div class="field col-12 md:col-5 chart-card mr-1">
              <h3>Productos Más Vendidos</h3>
              <Chart
                v-if="
                  productos_mas_vendidos &&
                  Object.keys(productos_mas_vendidos).length > 0
                "
                type="doughnut"
                :data="chartDataProdServ"
                :options="chartOptionsProdServ"
              />
              <div v-else class="no-data-message">
                No existen Productos Más Vendidos.
              </div>
            </div>
            <div class="field col-12 md:col-5 chart-card ml-1">
              <h3>Servicios Más Vendidos</h3>
              <Chart
                v-if="
                  servicios_mas_vendidos && servicios_mas_vendidos.length > 0
                "
                type="doughnut"
                :data="chartDataServicios"
                :options="chartOptionsServicios"
              />
              <div v-else class="no-data-message">
                No existen Servicios Más Vendidos.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-12 card">
        <div class="col-12 xl:col-12">
          <div
            v-if="
              'Dashboard ProductosStockCero' in authService.user.permissions
            "
          >
            <Panel
              class="p-panel-title"
              header="VER PRODUCTOS CON STOCK EN CERO"
              :toggleable="true"
              :collapsed="true"
            >
              <h5 class="text-red-500 text-center">
                <strong>PRODUCTO CON STOCK EN CERO</strong>
              </h5>
              <DataTable
                ref="dtproductosstockcero"
                :value="stocks"
                :paginator="true"
                :rows="20"
                :loading="enviando"
                responsiveLayout="scroll"
                :rowsPerPageOptions="[20, 50, 100]"
                :filters="buscar"
                :rowHover="true"
                class="p-datatable-sm"
              >
                <template #header>
                  <div
                    class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
                  >
                    <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="buscar['global'].value"
                        placeholder="Buscar..."
                      />
                    </span>
                  </div>
                </template>
                <template #loading>
                  <div class="flex align-items-center justify-content-center">
                    <ProgressSpinner />
                  </div>
                </template>
                <template #empty>
                  <span
                    class="flex align-items-center justify-content-center p-invalid"
                  >
                    No existen Productos con Stock en Cero!</span
                  >
                </template>
                <column field="nombre_sucursal" header="SUCURSAL"></column>
                <column
                  field="nombre_producto"
                  header="DETALLE/PRODUCTO"
                ></column>
                <column
                  field="cantidad"
                  header="CANTIDAD/STOCK"
                  class="text-right"
                >
                  <template #body="{ data }">
                    <div :class="stockClass(data)">
                      <strong>{{ data.cantidad }}</strong>
                    </div>
                  </template>
                </column>
              </DataTable>
            </Panel>
          </div>
          <div
            v-if="
              'Dashboard ProductosFechaVence' in authService.user.permissions
            "
          >
            <Panel
              class="p-panel-title"
              header="VER PRODUCTOS QUE VENCEN EN 30 DÍAS"
              :toggleable="true"
              :collapsed="true"
            >
              <h5 class="text-red-500 text-center">
                <strong>PRODUCTOS QUE VENCEN EN 30 D&Iacute;AS</strong>
              </h5>
              <DataTable
                ref="dtproductos_fecha_vence"
                :value="productos_fecha_vence"
                :rows="5"
                :paginator="true"
                :loading="enviando"
                responsiveLayout="scroll"
                :rowsPerPageOptions="[5, 10, 20, 50, 100]"
              >
                <template #loading>
                  <div class="flex align-items-center justify-content-center">
                    <ProgressSpinner />
                  </div>
                </template>
                <template #empty>
                  <span
                    class="flex align-items-center justify-content-center p-invalid"
                  >
                    No existen Productos con fecha de Vencimiento!</span
                  >
                </template>
                <column field="nombre_laborario" header="MARCA"></column>
                <column
                  field="nombre_producto"
                  header="DETALLE/PRODUCTO"
                ></column>
                <column field="fecha_vence" header="FECHA VENCIMIENTO"></column>
              </DataTable>
            </Panel>
          </div>
          <div
            v-if="'Dashboard ProductosStockMin' in authService.user.permissions"
          >
            <Panel
              class="p-panel-title"
              header="VER PRODUCTOS CON STOCK MÍNIMO"
              :toggleable="true"
              :collapsed="true"
            >
              <h5 class="text-red-500 text-center">
                <strong>PRODUCTOS CON STOCK M&Iacute;NIMO</strong>
              </h5>
              <DataTable
                ref="dtproductos_stock_minimo"
                :value="productos_stock_minimo"
                :rows="20"
                :paginator="true"
                :loading="enviando"
                responsiveLayout="scroll"
                :rowsPerPageOptions="[20, 50, 100]"
                :filters="buscar"
                :rowHover="true"
                class="p-datatable-sm"
              >
                <template #header>
                  <div
                    class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
                  >
                    <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="buscar['global'].value"
                        placeholder="Buscar..."
                      />
                    </span>
                  </div>
                </template>
                <template #loading>
                  <div class="flex align-items-center justify-content-center">
                    <ProgressSpinner />
                  </div>
                </template>
                <template #empty>
                  <span
                    class="flex align-items-center justify-content-center p-invalid"
                  >
                    No existen Productos con Stock M&Iacute;nimo!</span
                  >
                </template>
                <column field="nombre_laboratorio" header="MARCA"></column>
                <column
                  field="nombre_producto"
                  header="DETALLE/PRODUCTO"
                ></column>
                <column
                  field="stock_minimo"
                  header="STOCK M&Iacute;NIMO"
                ></column>
                <column field="stock_actual" header="STOCK ACTUAL"></column>
              </DataTable>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import Panel from "primevue/panel";
import { useAuth } from "@/stores";
import ProductService from "@/service/ProductService";
import StockService from "@/service/StockService";
import UserService from "@/service/UserService";

export default {
  components: {
    Panel,
  },
  data() {
    return {
      user: {},
      productos: null,
      clientes: null,
      proveedores: null,
      stocks: [],
      enviando: false,
      productos_fecha_vence: [],
      productos_stock_minimo: [],
      stockClass: (data) => {
        if (data.cantidad == 0) {
          return "text-red-500";
        }
      },
      collapsed: false,
      productos_mas_vendidos: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      loading: false,
      obtenerDatos: false,
      total_comision: 0,
      fecha_inicio: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ),
      fecha_fin: new Date(),
      servicios_mas_vendidos: [],
      chartDataProdServ: {},
      chartOptionsProdServ: {},
      chartDataServicios: {},
      chartOptionsServicios: {},
    };
  },
  authService: null,
  productService: null,
  stockService: null,

  created() {
    this.authService = useAuth();

    this.productService = new ProductService();
    this.userService = new UserService();
    this.stockService = new StockService();
  },
  mounted() {
    this.user = { ...this.authService.user };
    this.productosStockCero();
    this.Obtenerproductos_fecha_vence();
    this.Obtenerproductos_stock_minimo();
    this.panel_necesario_auth();
  },
  methods: {
    descargarComisionesPDF() {
      let datos = {
        id: this.user.id,
        nombre_usuario: this.user.name,
      };
      this.userService.descargarComisionesPDF(datos);
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    Obtenerproductos_fecha_vence() {
      if (this.collapsed == true) {
        this.enviando = false;
      } else {
        this.enviando = true;
        this.productService
          .getProductos_fecha_vence()
          .then((data) => {
            this.productos_fecha_vence = data.productos_fecha_vence ?? [];
            this.enviando = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    Obtenerproductos_stock_minimo() {
      if (this.collapsed == true) {
        this.enviando = false;
      } else {
        this.enviando = true;
        this.productService
          .getProductos_stock_minimo()
          .then((data) => {
            this.productos_stock_minimo = data.productos_stock_minimo;
            this.enviando = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    productosStockCero() {
      if (this.collapsed == true) {
        this.enviando = false;
      } else {
        this.enviando = true;
        this.stockService.productos_stock_cero().then((data) => {
          this.stocks = data.stocks;
          this.enviando = false;
        });
      }
    },
    panel_necesario_auth() {
      this.obtenerDatos = true;
      let datos = {
        id: this.user.id,
        panel: "panel_inicio",
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
      };
      this.userService.panel_necesario_auth(datos).then((data) => {
        this.productos = data.datos.total_productos ?? 0;
        this.clientes = data.datos.clientes ?? 0;
        this.proveedores = data.datos.proveedors ?? 0;
        this.productos_mas_vendidos = data.datos.productos_mas_vendidos ?? 0;
        this.servicios_mas_vendidos = data.datos.servicios_mas_vendidos ?? 0;
        this.total_comision = data.datos.total_comision ?? 0;

        // Configuración de gráficos
        if (this.productos_mas_vendidos.length > 0) {
          this.chartDataProdServ = {
            labels: this.productos_mas_vendidos.map((item) => item.descripcion),
            datasets: [
              {
                data: this.productos_mas_vendidos.map((item) => item.cantidad),
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
              },
            ],
          };
        }
        this.chartOptionsProdServ = {
          responsive: true,
          maintainAspectRatio: false,
        };

        //verifico si hay servicios mas vendidos
        if (this.servicios_mas_vendidos.length > 0) {
          this.chartDataServicios = {
            labels: this.servicios_mas_vendidos.map(
              (item) => item.nombre_servicio
            ),
            datasets: [
              {
                data: this.servicios_mas_vendidos.map((item) => item.cantidad),
                backgroundColor: ["#4BC0C0", "#FF9F40", "#9966FF"],
                hoverBackgroundColor: ["#4BC0C0", "#FF9F40", "#9966FF"],
              },
            ],
          };

          this.chartOptionsServicios = {
            responsive: true,
            maintainAspectRatio: false,
          };
        }
        this.obtenerDatos = false;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numero);
    },
  },
};
</script>
<style lang="scss" scoped>
.p-panel p {
  line-height: 1;
  margin: 0;
}
.p-panel-title {
  font-size: 1.5rem;
}
.p-invalid {
  color: red;
}
.p-panel-title {
  font-size: 1.5rem;
}
.dashboard-charts {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.chart-card {
  flex: 1;
  text-align: center;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-card h3 {
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #444444;
}

.no-data-message {
  color: red;
  font-style: italic;
  margin-top: 20px;
}
</style>
